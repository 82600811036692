import React from 'react';

import { graphql } from 'gatsby';
import 'react-aspect-ratio/aspect-ratio.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styled from 'styled-components';
import SEOComponent from '../components/seo';
import Title from '../components/typography/Title';
import { Wave3 } from '../components/wave';
import Layout from '../layout/main';
// import Desc from '../components/typography/Desc';

const Background = styled.div`
    background-color: transparent;
    margin-bottom: 277px;
    @media screen and (max-width: 767px) {
        margin-bottom: 153px;
    }
`;
const Heading = styled.div`
    margin-left: 176px;
    margin-right: 176px;
    padding-top: 149px;
    @media screen and (max-width: 1024px) {
        margin-left: 64px;
        margin-right: 64px;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        padding-top: 108px;
    }
`;

const Desc = styled.div`
    margin-top: 20px;
    span {
        font-family: 'Neuzeit Grotesk' !important;
        color: rgba(255, 255, 255, 0.7) !important;
        font-size: 23px !important;
        line-height: 32px !important;
        letter-spacing: normal !important;
        text-align: left !important;
    }
    a {
        color: #cca352;
        text-decoration: none;
    }
    @media screen and (max-width: 767px) {
        font-size: 17px;
        line-height: 24px;
    }
`;

export default function CookiePolicyPage({ data }) {
    const { hero, seo_content, ...props } = data.butterPage;

    return (
        <Layout menu="ABOUT US">
            <SEOComponent
                title="About Us"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Background>
                <Heading>
                    <Title>{hero[0].title}</Title>
                    <Desc
                        dangerouslySetInnerHTML={{
                            __html: hero[0].content,
                        }}
                    />
                </Heading>
                <Wave3 />
            </Background>
        </Layout>
    );
}

export const cookiePolicyPageQuery = graphql`
    query {
        butterPage(slug: { eq: "cookie-policy" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
                content
            }
        }
    }
`;
